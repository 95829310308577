.submitted__card {
    justify-content: center;
}

.submitHeader {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
}


