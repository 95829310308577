@import url("./variables.css");
@import url("./videoBannerPage.css");
@import url("./numberInputPage.css");
@import url("./submittedPage.css");

* {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}

p {
 line-height: 16.41px;
 font-size: 14px;
}
.card {
  background-color: var(--card-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card__text {
  font-size: 14px;
  text-align: center;
}

.btn {
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: bold;
  font-size: 16px;
}

.btn:hover {
  cursor: pointer;
}

.btn.active {
  background-color: var(--btn-active-bg);
  color: white;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
